// Your variable overrides

.fc-unthemed td.fc-today {
    background: #F2F5FF;
  }

.fc-button {
    display: inline-block;
    font-weight: 400;
    color: #002BB8;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.4em 0.65em;
    font-size: 1em;
    line-height: 1.5;
    border-radius: 0.25em;
  }

  .fc-button:hover {
    color: #002BB8;
    text-decoration: none;
  }
  
  .fc-button:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 39, 167, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 39, 167, 0.25);
  }
  
  .fc-button:disabled {
    opacity: 0.65;
  }

  .fc-button-primary:disabled {
    color: #fff;
    background-color: #002BB8;
    border-color: #002BB8;
  }
  
  
  /* "primary" coloring */
  .fc-button-primary {
    color: #fff;
    background-color: #002BB8;
    border-color: #002BB8;
  }
  
  .fc-button-primary:hover {
    color: #fff;
    background-color: #0027A7;
    border-color: #0027A7;
  }
  
  .fc-button-primary:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 39, 167, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 39, 167, 0.5);
  }
  .fc-button-primary:not(:disabled):active,
  .fc-button-primary:not(:disabled).fc-button-active {
    color: #fff;
    background-color: #001E82;
    border-color: #001E82;
  }

  
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

